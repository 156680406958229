import React from 'react'
import Photo from '@blocks/photo'
import cx from 'classnames'
import BlockContent from '@sanity/block-content-to-react'
import { serializers } from '@lib/serializers'

const SideBySideImageWithContent = ({ data }) => {
  const { shouldDecorateWithAngles } = data
  return (
    <section
      className={cx('side-by-side', {
        'has-block-angles': shouldDecorateWithAngles,
      })}
    >
      <div
        className={cx(`side-by-side__image col-span-12`, {
          'sm:col-span-4': !data.widerImage,
          'sm:col-span-7': data.widerImage,
          'sm:order-last': data.reverseOrder,
        })}
      >
        <Photo data={data} />
      </div>
      <div
        className={cx(
          `side-by-side__content flex col-span-12 p-4 sm:p-10 md:p-20 `,
          data.itemAlignment,
          {
            'sm:col-span-5': data.widerImage,
            ' sm:col-span-8': !data.widerImage,
          }
        )}
      >
        <BlockContent
          renderContainerOnSingleChild
          className="rc"
          blocks={data.content}
          serializers={serializers}
        />
      </div>
    </section>
  )
}

export default SideBySideImageWithContent
