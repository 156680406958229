import React from 'react'

import PhotoComponent from '@components/photo'

const Photo = ({ data = {} }) => {
  const { photo } = data

  if (!photo) return null
  return (
    <PhotoComponent
      photo={photo}
      srcsizes={[800, 1000, 1200, 1600]}
      sizes="100vw"
    />
  )
}

export default Photo
